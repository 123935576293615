<template>
  <div class="body">
    <heads :more="showMore" :msg="title"></heads>
    <div class="form">
      <van-form @submit="onSubmit">
        <van-field v-model="postForm.order_sn" label="订单编号" label-width="90px" readonly />
        <!-- 发票类型 -->
        <van-field
          v-model="fp_typeStr"
          label="发票类型"
          label-width="90px"
          readonly
          right-icon="arrow"
          @click="show_fpType = true"
        />
        <van-popup v-model="show_fpType" position="bottom">
          <van-picker
            show-toolbar
            title="选择发票类型"
            :columns="fpTypes"
            value-key="value"
            @confirm="onSelectFpType"
            @cancel="show_fpType = false"
          />
        </van-popup>
        <!-- 发票内容 -->
        <van-field label="发票内容" label-width="90px">
          <template #input>
            <van-radio-group
              v-model="postForm.invoice_content"
              direction="horizontal"
              @change="onSelectFpCnt"
            >
              <div v-for="(item,index) in fp_cnts">
                <van-radio :name="item.id">
                  {{ item.value }}
                  <template #icon="props">
                    <img class="radio_icon" :src="props.checked ? radio_normal : radio_red">
                  </template>
                </van-radio>
              </div>
            </van-radio-group>
          </template>
        </van-field>
        <!-- 电子、纸质 平台发票 -->
        <!-- 抬头类型 -->
        <van-field v-show="postForm.invoice_type !== 3&&postForm.invoice_type !== 5" label="抬头类型" label-width="90px">
          <template #input>
            <van-radio-group
              v-model="postForm.title_type"
              direction="horizontal"
              @change="onSelectFpUp"
            >
              <div v-for="(item1,index1) in fp_ups">
                <van-radio :name="index1">
                  {{ item1.title }}
                  <template #icon="props">
                    <img class="radio_icon" :src="props.checked ? radio_normal : radio_red">
                  </template>
                </van-radio>
              </div>
            </van-radio-group>
          </template>
        </van-field>
        <!-- 抬头，个人 -->
        <van-field
          v-show="(postForm.invoice_type !== 3&&postForm.invoice_type !== 5) && postForm.title_type===0"
          v-model="fp_up_str"
          label="发票抬头"
          placeholder="请输入发票抬头"
          label-width="90px"
          :readonly="postForm.title_type===0 ? true : false"
        />
        <!-- 单位名称 -->
        <div class="inp_red">
          <van-field
            v-show="(postForm.invoice_type !== 3&&postForm.invoice_type !== 5) && postForm.title_type===1"
            v-model="postForm.invoice_title"
            label="单位名称"
            placeholder="请输入单位名称"
            label-width="90px"
          />
          <span>*</span>
        </div>
        <!-- 税号 -->
        <div class="inp_red">
          <van-field
            v-show="(postForm.invoice_type !== 3&&postForm.invoice_type !== 5) && postForm.title_type === 1"
            v-model="postForm.taxpayer_identifier"
            label="税号"
            placeholder="请输入税号"
            label-width="90px"
          />
          <span>*</span>
        </div>
        <!-- 增值税发票 -->
        <!-- 发票抬头 -->
        <van-field
          v-show="(postForm.invoice_type === 3|| postForm.invoice_type === 5)"
          v-model="fp_up_str"
          label="抬头类型"
          placeholder="请输入抬头类型"
          label-width="90px"
          readonly
        />
        <!-- 单位名称 -->
        <div class="inp_red">
          <van-field
            v-show="(postForm.invoice_type === 3||postForm.invoice_type === 5)"
            v-model="postForm.invoice_title"
            label="单位名称"
            placeholder="请输入单位名称"
            label-width="90px"
          />
          <span>*</span>
        </div>
        <!-- 税号 -->
        <div class="inp_red">
          <van-field
            v-show="(postForm.invoice_type === 3||postForm.invoice_type === 5)"
            v-model="postForm.taxpayer_identifier"
            label="纳税人识别码"
            placeholder="请输入纳税人识别码"
            label-width="90px"
          />
          <span>*</span>
        </div>
        <!-- 注册地址 -->
        <!-- <div class="inp_red">
          <van-field
            v-show="postForm.invoice_type === 3 || postForm.invoice_type === 5 || postForm.title_type === 1"
            v-model="postForm.reg_address"
            label="注册地址"
            placeholder="请输入注册地址"
            label-width="90px"
          />
          <span v-show="postForm.invoice_type === 3 || postForm.invoice_type === 5">*</span>
        </div> -->
        <!-- 注册电话 -->
        <!-- <div class="inp_red">
          <van-field
            v-show="postForm.invoice_type === 3||postForm.invoice_type === 5 || postForm.title_type === 1"
            v-model="postForm.reg_tel"
            label="注册电话"
            placeholder="请输入注册电话"
            label-width="90px"
          />
          <span v-show="postForm.invoice_type === 3||postForm.invoice_type === 5">*</span>
        </div> -->
        <!-- 开户银行 -->
        <!-- <div class="inp_red">
          <van-field
            v-show="postForm.invoice_type === 3||postForm.invoice_type === 5 || postForm.title_type === 1"
            v-model="postForm.bank_name"
            label="开户银行"
            placeholder="请输入开户银行"
            label-width="90px"
          />
          <span v-show="postForm.invoice_type === 3||postForm.invoice_type === 5">*</span>
        </div> -->
        <!-- 银行账号 -->
        <!-- <div class="inp_red">
          <van-field
            v-show="postForm.invoice_type === 3||postForm.invoice_type === 5 || postForm.title_type === 1"
            v-model="postForm.bank_account"
            label="银行账号"
            placeholder="请输入银行账号"
            label-width="90px"
          />
          <span v-show="postForm.invoice_type === 3||postForm.invoice_type === 5">*</span>
        </div> -->
        <!-- 开票电话 -->
        <div class="inp_red">
          <van-field v-model="postForm.mobile" label="手机号码" placeholder="请输入手机号码" label-width="90px" />
          <span>*</span>
        </div>
        <!-- 备注 -->
        <van-field
          v-model="postForm.remark"
          label="备注"
          type="textarea"
          placeholder="请输入备注"
          label-width="90px"
          maxlength="110"
          show-word-limit
        />
        <div class="invoice_tips">
          <p>注：请确保发票上单位名称、纳税人识别码、手机号码</p>
          <p class="line2">申请后3-5天工作日可以在已购课程里下载发票</p>
        </div>
        <!-- 确定 -->
        <div class="doneBtn">
          <van-button
            block
            type="info"
            native-type="submit"
          >{{ postForm.invoice_type === 1 || postForm.invoice_type === 4 || postForm.invoice_type === 5 ? '去申请' : '支付邮费' }}</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { getInvoiceSetInfo, saveInvoice } from '@/api/invoice.js'

export default {
  components: {
    heads
  },
  data() {
    return {
      title: '开发票',
      showMore: true,
      show_fpType: false,
      radio_red: require('@/static/images/my_index/radio_normal.png'),
      radio_normal: require('@/static/images/my_index/radio_red.png'),
      // 发票类型
      fpTypes: [],
      // 发票内容
      fp_cnts: [],
      // 发票抬头
      fp_ups: [
        { id: 0, title: '个人' },
        { id: 1, title: '单位' }
      ],
      // 发票类型，字符串
      fp_typeStr: '',
      // 抬头类型，字符串
      fp_up_str: '个人',
      postForm: {
        order_sn: this.$route.query.order_sn ? this.$route.query.order_sn : '',
        // 发票类型
        invoice_type: 0,
        // 发票内容
        invoice_content: 0,
        // 抬头类型
        title_type: 0,
        // 抬头内容，公司名
        invoice_title: '',
        // 税号
        taxpayer_identifier: '',
        // 注册地址
        reg_address: '',
        // 注册电话
        reg_tel: '',
        // 开户银行
        bank_name: '',
        // 银行账号
        bank_account: '',
        // 开票 手机
        mobile: '',
        // 备注
        remark: ''
      }
    }
  },
  created() {
    this.getInvoiceMsg()
  },
  methods: {
    // 获取发票内容信息
    getInvoiceMsg() {
      const info = {
        order_sn: this.postForm.order_sn
      }
      getInvoiceSetInfo(info).then((res) => {
        console.log(res)
        const result = res.result ? res.result : {}
        // const content = result.content ? result.content : []
        var type = result.type ? result.type : []
        // 发票类型
        this.fpTypes = type
        // 默认第一个
        if (type.length > 0) {
          const first = type[0]
          this.postForm.invoice_type = first.id
          this.fp_typeStr = first.value
        }
        // 默认个人
        if (this.postForm.invoice_type == 3 || this.postForm.invoice_type == 5) {
          this.onSelectFpUp(1)
        } else {
          this.onSelectFpUp(0)
        }
        // 发票内容
        const content = this.postForm.invoice_type ? this.fpTypes[this.fpTypes.findIndex(i => i.id == this.postForm.invoice_type)].content : []
        if (content.length > 0) {
          this.postForm.invoice_content = content[0].id
        }
        this.fp_cnts = content
      })
    },
    // 发票类型
    onSelectFpType(item) {
      this.postForm.invoice_type = item.id
      this.fp_typeStr = item.value
      this.show_fpType = false

      this.fp_cnts = item.content
      if (this.fp_cnts && this.fp_cnts.length > 0) {
        this.postForm.invoice_content = this.fp_cnts[0].id || ''
      }

      // 切换 置空已填写内容，暂定，根据需求调整
      this.postForm.invoice_title = ''
      this.postForm.taxpayer_identifier = ''
      this.postForm.reg_address = ''
      this.postForm.reg_tel = ''
      this.postForm.bank_name = ''
      this.postForm.bank_account = ''

      if (item.id === 3 || item.id === 4 || item.id === 5) {
        // 增值税发票
        this.onSelectFpUp(1)
      } else {
        this.onSelectFpUp(0)
      }
    },
    // 发票内容
    onSelectFpCnt(t) {
      console.log('t ' + t)
      this.postForm.invoice_content = t
    },
    // 抬头类型
    onSelectFpUp(t) {
      this.postForm.title_type = this.fp_ups[t].id
      this.fp_up_str = this.fp_ups[t].title
      if (t === 0) {
        this.postForm.invoice_title = this.fp_up_str
      } else {
        this.postForm.invoice_title = ''
      }
    },
    // 提交
    onSubmit() {
      console.log(this.postForm)
      if (this.postForm.invoice_type === 3 || this.postForm.invoice_type === 5) {
        // 增值税发票
        if (this.postForm.invoice_title === '') {
          return this.$toast.fail('请输入单位名称')
        }
        if (this.postForm.taxpayer_identifier === '') {
          return this.$toast.fail('请输入纳税人识别码')
        }

        // if (this.postForm.reg_address === '') {
        //   return this.$toast.fail('请输入注册地址')
        // }
        // if (this.postForm.reg_tel === '') {
        //   return this.$toast.fail('请输入注册电话')
        // }
        // if (this.postForm.bank_name === '') {
        //   return this.$toast.fail('请输入开户银行')
        // }
        // if (this.postForm.bank_account === '') {
        //   return this.$toast.fail('请输入银行账号')
        // }
      } else {
        if (this.postForm.title_type === 1) {
          // 单位
          if (this.postForm.invoice_title === '') {
            return this.$toast.fail('请输入单位名称')
          }
          if (this.postForm.taxpayer_identifier === '') {
            return this.$toast.fail('请输入税号')
          }
        }
      }
      if (this.postForm.mobile === '') {
        return this.$toast.fail('请输入手机号')
      }
      // if (this.postForm.remark === '') {
      //   return this.$toast.fail('请输入备注')
      // }
      saveInvoice(this.postForm).then((res) => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          // 开成功
          const result = res.result ? res.result : {}
          const invoice_type = result.invoice_type
          if (invoice_type === 1 || invoice_type === 4 || invoice_type === 5) {
            // 普通电子
            this.$router.go(-1)
          } else {
            const pay_url = result.pay_url
            window.location.href = pay_url
          }
        } else {
          const result = res.result
          this.$toast.fail(result)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice_tips{
  padding-left: 20px;
  margin-top: 20px;
  color:#999;
  font-size: 28px;
  .line2{
    text-indent: 56px;
  }
}
.body {
  width: 100%;
  // height: 100%;
  // background-color: aqua;
}
// .form {
// }
.radio_icon {
  width: 30px;
  height: 30px;
}
.doneBtn {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 28px;
}
/deep/ .van-button--info {
  background-color: #ee2e2e;
  border: 1px solid #ee2e2e;
}
/deep/ .van-button__content {
  font-size: 28px;
  color: #fff;
}
/deep/ .van-picker__cancel {
  color: #ee2e2e;
  font-size: 32px;
  font-weight: 500;
}
/deep/ .van-picker__confirm {
  color: #ee2e2e;
  font-size: 32px;
  font-weight: 500;
}
.inp_red{
  position: relative;
  span{
    color: #ee2e2e;
    position: absolute;
    top: 24px;
    left: 20px;
  }
}
</style>
