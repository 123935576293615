import request from '@/utils/ucenterrequest.js'

// 获取开发票 配置信息
export function getInvoiceSetInfo(params) {
  return request({
    url: '/lesson/Invoice/getInvoiceSetInfo',
    method: 'get',
    params
  })
}
// 校验是否能开发票
export function checkCanInvoice(params) {
  return request({
    url: '/lesson/invoice/getInvoiceStatus',
    method: 'get',
    params
  })
}
// 开发票 保存
export function saveInvoice(params) {
  return request({
    url: '/lesson/invoice/saveInvoice',
    method: 'get',
    params
  })
}
